import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { REST_URLS } from '../resturls';

let requestQueue = [];

const executeCombinedRequest = (currentRequestQueue) => {
  if (currentRequestQueue.length === 0) return;

  const allInstruments = Array.from(
    new Set(currentRequestQueue.flatMap(({ instruments }) => instruments))
  );

  const { GET_LIVE_DATA } = REST_URLS();
  const parameters = {
    method: 'POST',
    body: JSON.stringify({ instruments: allInstruments }),
  };

  const uri = `${GET_LIVE_DATA}`;

  wrappedFetch(uri, parameters)
    .then((response) => response.json())
    .then((result) => {
      const newMarketData = {};
      const newAdditionalData = {};
      const additionalData = result.additionalData || {};

      for (const key in additionalData) {
        const item = additionalData[key];
        newAdditionalData[key] = {
          ...item,
          firstOpen: item?.firstOpen || item?.prevDayLtp,
          firstOi: item?.firstOi || item?.prevDayOi,
        };
      }

      for (const key in result.marketData) {
        const item = result.marketData[key];
        newMarketData[key] = {
          ...item,
          currentDayOpen: newAdditionalData[key]?.firstOpen,
          previousDayClose: newAdditionalData[key]?.prevDayLtp,
        };
      }

      // Distribute the data to each original request
      currentRequestQueue.forEach(({ instruments, resolve }) => {
        const filteredMarketData = {};
        const filteredAdditionalData = {};

        instruments.forEach((instrument) => {
          filteredMarketData[instrument] = newMarketData[instrument];
          filteredAdditionalData[instrument] = newAdditionalData[instrument];
        });

        resolve({
          marketData: filteredMarketData,
          additionalData: filteredAdditionalData,
        });
      });
    })
    .catch((error) => {
      currentRequestQueue.forEach(({ reject }) => reject(error));
    });
};

// Debounce the combined request execution
const debouncedExecuteRequest = _.debounce(() => {
  executeCombinedRequest(requestQueue);
  requestQueue = [];
}, 500);

export const getInstrumentsBasedLiveData = (instruments) => {
  // Execute the combined request after debounce
  return new Promise((resolve, reject) => {
    // Add request to the queue
    requestQueue.push({
      instruments: _.split(instruments, ','),
      resolve,
      reject,
    });

    debouncedExecuteRequest();
  });
};
