import { BSE_INDEXES, userRunTypes } from './habitual-configs';
import _ from 'lodash';
import { fetchApiFunc } from 'habitual-analytics/brokers';

/* brokerConfigs = {
    broker: 'zerodha' || 'upstox' || 'aliceblue',
    brokerClientId: '',
    brokerClientAccessToken: '',
  }
*/

export const defaultOrderPreferences = {
  pCode: 'MIS',
  prctyp: 'MKT',
  ret: 'DAY',
  lot: '1',
  limitType: 'percent',
  limitPriceOrPercent: '1',
  triggerType: 'percent',
  triggerPriceOrPercent: '0.5',
};

export const defaultSettingPreferences = {
  changeFromType: 'previousDayClose',
  marginBenefit: 'no',
  notificationSound: 'yes',
  userMode: 'buyer',
  directPlaceOrder: 'no',
};

export const defaultHistoricalConfigs = {
  userRunType: userRunTypes.live,
  historicalDateAndTime: null,
};

export const defaultExchangeSegmentSupport = {
  isEquity: true,
  isFutures: true,
  isOptions: true,
};

const getCurrentDomainConfig = () => {
  const currentSiteUrl = window?.location?.host;
  const domainConfigs = window?.instaConstants?.brokerDomainConfigs || {};

  // Default configuration
  const defaultConfig = {
    broker: '',
    logoName: 'instaoptions-logo.svg',
    eligibleOrderTypes: ['live', 'paper'],
    ignoreTabs: [],
    brokerMarginRequiredEnabled: true,
    brokerMarginAvailableEnabled: true,
    brokerMarginVisiblity: [], // #3 places ['orders-modal', 'basket', 'orders-tab']
    isBseEnabled: false,
    bseEnabledInstruments: BSE_INDEXES,
    shouldEnableModifyOrder: false,
  };

  // Get the configuration for the current domain, or use an empty object if not found
  const config = _.get(domainConfigs, currentSiteUrl, {});

  // Merge the default configuration with the domain-specific configuration
  return {
    ...defaultConfig,
    ...config,
  };
};

let dynamicAppConfigs = {
  brokerConfigs: {},
  envs: {},
  orderPreferences: {},
  settingPreferences: {},
  cacheVersion: '',
  mobileToken: '',
  theme: 'light',
  postBackSupportedBrokers: [],
  historicalConfigs: {},
  exchangeSegmentSupport: {},
  isMobile: false,
  isPaperTrade: false,
  domainConfigs: getCurrentDomainConfig(),
};

export const initDynamicAppConfigs = (newConfig) => {
  dynamicAppConfigs = { ...dynamicAppConfigs, ...newConfig };
};

export const getDynamicAppConfigs = () => {
  return {
    ...dynamicAppConfigs,
    isValidAccessToken: async () =>
      fetchApiFunc('isValidAccessToken', dynamicAppConfigs.brokerConfigs),
  };
};
